import React from 'react';
import { string, shape } from 'prop-types';
import { Card, CardContent } from '@andes/card';
import { Button } from '@andes/button';

import Section from '../../../commons/section';
import ComposedLabel from '../../../commons/composed-label';
import withTracker from '../../../commons/with-tracker';

const RegistrationMobile = ({
  type,
  header: { title },
  primary_action,
  composed_action = null,
}) => (
  <Section type={type}>
    <div className="container">
      <Card>
        <CardContent>
          <p className="home-registration-title">{title.text}</p>
          <div className="home-registration-buttons">
            <Button
              id="primary_action"
              onClick={() => window.location.assign(primary_action.target)}
            >
              {primary_action.label.text}
            </Button>
            <div className="home-registration-register">
              <ComposedLabel {...composed_action} />
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  </Section>
);

RegistrationMobile.propTypes = {
  type: string.isRequired,
  header: shape({
    text: string,
    color: string,
    bg_color: string,
  }).isRequired,
  primary_action: shape({
    label: shape({
      text: string.isRequired,
      color: string,
      bg_color: string,
    }).isRequired,
    target: string.isRequired,
  }).isRequired,
  composed_action: shape({
    text: string.isRequired,
    color: string,
    bg_color: string,
    values: shape({}),
  }).isRequired,
};

export default withTracker(RegistrationMobile);

